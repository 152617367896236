/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('#splashSlider').owlCarousel({
          singleItem: true,
          navigation: true,
          // transitionStyle: "fade",
          autoPlay: 7000,
          slideSpeed: 750,
          autoHeight : false
        });

        $('.logos').each(function(i, el) {
          $(el).owlCarousel({
            items : 5,
            autoPlay: 7000,
            pagination: false,
            slideSpeed: 750,
            afterInit: function() {
              var containerHeight = $(el).outerHeight();

              $('.logo', el).each(function(j, c) {
                $(c).css('height', containerHeight + 'px');
              });
            }
          });
        });

        if ($('ul.products').length) {
          var itemsPerRow = 3;

          if ($('body').hasClass('single-product')) {
            itemsPerRow = 4;
          }

          $('ul.products').each(function(i, el) {
            var leftover = $('.product', el).length % itemsPerRow,
                missing = itemsPerRow - leftover;

            for (var j = 0; j < missing; j++) {
               var productEl = $('.product', el).first().clone().empty().removeClass('last first').addClass('gap');
              $(el).append(productEl);
            }
          });
        }

        if ($('#footer-bar').length) {
          $('#footer-bar-spacer').height($('#footer-bar').innerHeight());

          $('#site-footer').waypoint({
              handler: function(direction) {
                $('#footer-bar').toggleClass('docked', direction === 'down');
                $('#footer-bar-spacer').toggleClass('docked', direction === 'down');
              },
              offset: '100%'
          });
        }
      },
      finalize: function() {
        objectFitImages();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

